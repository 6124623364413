footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    padding-top: 62px;
    padding-bottom: 62px;
    justify-self: flex-end;
    width: 100%;
    height: calc(209px - 62px - 32px);
    background-color: $black;
    color: $white;
    margin-top: 30px;
  
    & img {
        width: 122px;
    }
}

@include breakpoints(500) {
    footer {
        height: calc(209px - 62px - 62px);
        font-size: 12px;
    }
}