.host {
    color: $color-1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 196px;

    
    &_img {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 50%;
    }
    
        &_name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
}