.gallery {
    position: relative;
    width: calc(100% - 40px);
    margin: 0 20px 50px 20px;
    height: 415px;
    border-radius: 25px;
    overflow: hidden;
  
    & img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 415px;
        object-fit: cover;
    }
  
        &_arrowLeft,
        &_arrowRight {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            padding: 5px 10px;
            z-index: 1;
            right: 25px;
            width: 48px;
        }
  
            &_arrowLeft {
                left: 3%;
            }
  
                &_arrowRight {
                    right: 3%;
                }
  
                    &_counter {
                        font-size: 24px;
                        color: $white;
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        padding: 5px 10px;
                    } 

}

@include breakpoints(500) {
    .gallery {
        height: 255px;

        & img {
            height: 255px;
          }

            &_arrowLeft,
            &_arrowRight {
            width: 18px;
            }
                &_counter {
                    font-size: 18px;
                }
    }
}