.about {
    display: block;
    gap: 0 75px;
    line-height: 22.5px;
    list-style: none;
    margin: 25px 20px 0 20px;
}


@include breakpoints(1340) {
    .about {
    margin: 25px 40px 0 40px;
    }
}

@include breakpoints(700) {
    .about {
    margin: 25px 20px 0 20px;
    }
}