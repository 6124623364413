.thumb {
    position: relative;
    width: 340px;
    height: 340px;
    overflow: hidden;
    border-radius: 10px;

  
    & img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  
        &_bg {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        }
  
            & p {
                position: absolute;
                bottom: 0;
                left: 0px;
                padding: 10px;
                margin: 0;
                font-weight: 500;
                font-size: 1.125rem;
                color: $white;
            }
}
 
@include breakpoints(1239) {
    .thumb {
        width: 100%;
    }
}

@include breakpoints(850) {
    .thumb {
        width: calc(100vw - 55px);
        height: 300px;
    }
}

@include breakpoints(500) {
    .thumb {
        width: calc(100vw - 35px);
        height: 255px;   
    }
}