$color-1: #FF6060;
$white: white;
$black: black;


$breakpoints:(
    300: 300px,
    400: 400px,
    500: 500px,
    650: 650px,
    700: 700px,
    850: 850px,
    920: 920px,
    1040: 1040px,
    1239: 1239px,
    1340: 1340px,
    1490: 1490px,
);
