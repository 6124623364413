.banner {
    margin: 0 auto 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    height: 228px;
    width: calc(100% + 20px);

  
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 25px;
    }
  
        &_text {
            position: absolute;
            margin: 0;
            top: 0;
            padding: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 3rem;
            font-weight: 500;
            background-color: rgba(0, 0, 0, 0.3);
        }
} 

@include breakpoints(1340) {
    .banner {
      width: 93%;
      border-radius: 23px;
    }
}

@include breakpoints(1040) {
    .banner {
      border-radius: 23px;

        & img {
        border-radius: 23px;
        }
            &_text {
            font-size: 2.8rem;
            }
    }
}

@include breakpoints(850) {
    .banner {
      border-radius: 21px;
      height: 210px;

      &_text {
        font-size: 2.4rem;
        }
    }
}

@include breakpoints(700) {
    .banner {
      border-radius: 21px;
      height: 160px;

      &_text {
        font-size: 1.9rem;
        }
    }
}

@include breakpoints(500) {
    .banner {
      border-radius: 18px;
      height: 111px;

      &_text {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        font-size: 1.5rem;
        }
    }
}