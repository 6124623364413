.dropdown_section {
    display: inline-flex;
    gap: 0 75px;
    line-height: 22.5px;
    list-style: none;
    margin: 25px 20px 0 20px;
    width: 95%;
}

.dropdown_section > .dropdown {
    width: 50%;
}

.dropdown {
    margin-bottom: 35px;
    text-align: left;

    &_header {
    align-items: center;
    background-color: $color-1;
    border-radius: 10px;
    display: inline-flex;
    height: 25%;
    min-height: 50px;
    width: 100%;
  }
  
  &_tilte {
    color: $white;
    font-weight: 500;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    margin: 5px 10px;
  }
  
  &_fleche {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    height: 18px;
    width: 100%;
  }
  &_fleche.true {
    justify-content: start;
    transform: rotate(-180deg);
  }
  
  &_description {
    background-color: #f6f6f6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: $color-1;
    width: 50px;
    height: 75%;
    margin-top: -10px;
    min-height: 100px;
    padding: 25px 10px 10px 10px;
    font-weight: 400;
    font-size: 18px;
  }
}

@include breakpoints(1490) {
    
    .dropdown_section {
        display: block;
    }

    .dropdown_section > .dropdown {
        width: 100%;
    }
    
    .dropdown {
        margin-bottom: 20px;
    }
}

@include breakpoints(650) {
  .dropdown {

      &_header {
      border-radius: 5px;
      width: 98%;
    }
    
      &_tilte {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        margin: 5px 10px;
      }
      
        &_fleche {
          height: 12px;
        }
          &_fleche.true {
            justify-content: start;
            transform: rotate(-180deg);
          }
          
            &_description {
              font-weight: 400;
              font-size: 12px;
            }
  }
}

@include breakpoints(400) {
  .dropdown_section {
  margin: 25px 10px 0 10px;
  }
}