.rating {
    display: grid;
    margin-top: 24px;
    grid-template-columns: repeat(5, 30px);
    gap: 10px;
    
        & img {
            width: 30px;
        }
    }
  
@include breakpoints(650) {
    .rating {
      margin-top: 0;
      grid-template-columns: repeat(5, 15px);
      gap: 5px;

        & img {
            width: 15px;
        }
    }
  }